const apiUrl = process.env.NODE_ENV === 'development'
    ? 'http://adm-commerce.test'
    // ? 'http://127.0.0.1:8000'
    : 'https://api.admin-ecommerce.ingco.co.id' 
    // : 'http://127.0.0.1:8000'

const googleService = {
    mapApiKey: process.env[`VUE_APP_GOOGLE_MAPS_KEY_${process.env.NODE_ENV.toUpperCase()}`],
}

export default {
    apiUrl,
    googleService,
}
