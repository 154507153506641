import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
	isUserLoggedIn,
	getUserData,
	getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import system from './routes/system'
import contentManagement from './routes/content-management'
import calonAgen from './routes/calon-agen'
import order from './routes/order'
import payment from './routes/payment'
import warehouse from './routes/warehouse'
import serviceCenter from './routes/service-center'
import report from './routes/report'
import faq from './routes/faq'
import returnSales from './routes/return-sales'
import refund from './routes/refund'
import tags from './routes/tags'
import karir from './routes/karir'
import newsNotification from './routes/news-notification'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{ path: '/', redirect: { name: 'dashboard' } },
		...apps,
		...dashboard,
		...pages,
		...system,
		...contentManagement,
		...calonAgen,
		...order,
		...payment,
		...warehouse,
		...serviceCenter,
		...report,
		...faq,
		...returnSales,
		...refund,
		...tags,
		...karir,
		...newsNotification,
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

router.beforeEach((to, _, next) => {
	const isLoggedIn = isUserLoggedIn()

	if (!canNavigate(to)) {
		// Redirect to login if not logged in
		if (!isLoggedIn) return next({ name: 'auth-login' })

		// If logged in => not authorized
		return next({ name: 'misc-not-authorized' })
	}

	// Redirect if logged in
	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		const userData = getUserData()
		next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
	}

	return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
